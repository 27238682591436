<template>
  <b-overlay
    :show="applyOverlayD"
    :class="applyOverlayD ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlayD">
      <!-- Assignación de representantes -->
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('clubs.representatives.representativesData') }}</strong>
            </h5>
          </b-col>
          <b-col md="6">
            <span
              v-if="nRepresentatives > 1 && checkPermission(['DELETE_ASSIGN_REPRESENTATIVES', 'ALLOW_ALL'])"
              class="float-right mt-1 ml-1"
            >
              {{ $t( 'buttons.delete') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="$emit('delete-representative', actions.representativeId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
            <span
              v-if="checkPermission(['EDIT_ASSIGN_REPRESENTATIVES', 'ALLOW_ALL'])"
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-representative', actions.representativeId)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col md="12">
        <b-list-group>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.instrumentNumbers') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-for="(instrument, index) in representativesInfoD.instrument_numbers"
                  :key="instrument.hash"
                  class="float-left"
                >
                  <span v-if="index != 0">, </span>
                  {{ instrument.instrument_number }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.representatives.representatives') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-for="(partnerR, index) in representativesInfoD.partners"
                  :key="partnerR.hash"
                  class="float-left"
                >
                  <span v-if="index != 0">, </span>
                  <strong>{{ partnerR.name }}</strong>
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.representatives.otherRepresentatives') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-for="(otherR, index) in representativesInfoD.representatives"
                  :key="otherR.hash"
                  class="float-left"
                >
                  <span v-if="index != 0">, </span>
                  <strong>{{ otherR.name }}</strong>
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.representatives.powersGranted') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-for="(powerG, index) in representativesInfoD.power_granteds"
                  :key="powerG.hash"
                  class="float-left"
                >
                  <span v-if="index != 0">, </span>
                  {{ powerG.labelables[0].label }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.representatives.specialPowers') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-for="powerS in representativesInfoD.power_granteds"
                  :key="powerS.hash"
                  class="float-left"
                >
                  {{ powerS.pivot.special_powers_granted }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.representatives.validityPeriod') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="!representativesInfoD.undefined_date"
                  class="float-left"
                >
                  {{ representativesInfoD.date_init | formatDate }} {{ $t('clubs.representatives.to') }} {{ representativesInfoD.date_end | formatDate }}
                </span>
                <span
                  v-else
                  class="float-left"
                >
                  {{ $t('generic.undefinedDate') }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!--/ Assignación de representantes -->
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BRow, BCol, BListGroup, BListGroupItem, BButton, BOverlay } from 'bootstrap-vue'
import representativeService from '@/services/representativeService'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    nRepresentatives: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      representativesInfoD: {},
      applyOverlayD: false,
    }
  },

  created() {
    this.fetchAssignRepresentativeInfo()
  },

  methods: {
    fetchAssignRepresentativeInfo() {
      this.applyOverlayD = true

      representativeService.getRepresentativeInfo(this.actions.representativeId).then(({ data }) => {
        this.representativesInfoD = data.data
        this.applyOverlayD = false
      }).catch(error => {
        this.applyOverlayD = false
        this.responseCatch(error)
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
